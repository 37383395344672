export const pageview = () =>{
    window.fbq('track', 'page_viewed')
}

export const register = () =>{
    window.fbq('track', 'register_button_clicked')
}

export const RegisterSuccess = () => {
    window.fbq('track', 'Registration_Success')
}